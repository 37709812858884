import { useEffect }              from 'react'
import { _bootstrap }             from 'state/store.bootstrap'
import { _project }               from 'state/store.projects'
import { _isometry }              from 'state/store.isometry'
import { active_project }         from 'state/store.global'
import { useFetch }               from 'hooks/fetch/useFetch'
import { useGet, setFocus }       from 'state/jotai'
import { array }                  from 'utilities/utility.misc'
import { preloadImage }           from 'utilities/utility.images'
import { ISO_FRAMES, ISO_CONFIG } from 'api/api'
import eachOfLimit                from 'async/eachOfLimit'

export const PreloadFrames_Poly = () => {

  const { pin, pid } = useGet(active_project)
  
  const type   = useGet(_bootstrap).type
  const cache  = useGet(_isometry).status
  const poly   = type === 'poly'
  const file   = poly ? 'main/Poly/PolyPreloadFrames.jsx' : ''
  const url    = pid ? ISO_CONFIG : null
  const config = useFetch({ url:poly?url:null, file }).D

  const setFrames  = setFocus(_isometry, 'frames')
  const setPercent = setFocus(_isometry, 'percent')
  const setStatus  = setFocus(_isometry, 'status')
  const setConfig  = setFocus(_isometry, 'config')

  useEffect(()=>{
    if ( cache && poly && config && pin >= 0 && !['FETCHING','DONE'].includes(cache[pin][pid]) ) {
        const x = config[pin]
        const ARR = []
        const PATH = x.PNG_PATH
        const NUM = x.IMAGE_NUM
        const INI = x.FIRST_IMAGE
        const SNP = x.IMAGE_SNAPFRAMES
        const EXT = x.FILE_EXT
        const FNC = x => `${ISO_FRAMES}${PATH}/${x + INI}.${EXT}`
        const COL = array(NUM, FNC, SNP)
        setConfig ( a => a?.map((d,i)=>fn(d,i,pin,pid,x)) )
        eachOfLimit(COL, 20, 
          ( val, idx, callback ) => {
            const IMG     = preloadImage(val)
            const STATUS  = ( idx !== NUM - 1 ) ? 'FETCHING' : 'DONE'
            const PERCENT = ( idx * ( 100 / NUM ) ).toFixed() 
            ARR[idx] = { [INI+idx]:IMG }
            setFrames ( a => a?.map((d,i)=>fn(d,i,pin,pid,ARR     )) )
            setPercent( a => a?.map((d,i)=>fn(d,i,pin,pid,PERCENT )) )
            setStatus ( a => a?.map((d,i)=>fn(d,i,pin,pid,STATUS  )) )
            IMG.onload = ( ) => callback()
          },
          () => {
            // console.log( 'Done preloading frames for', pin, pid )
          }
        )
    }
  },[poly, config, pin, pid])
  return null
}
const fn = (x,y,z,v,w) => (y===z) ? { [v] : w } : x