import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const location = atom({});

export const ip = atom("");

export const favorites = atomWithStorage("favorites", []);
export const compares = atomWithStorage("compares", []);
export const comparesData = atom([]);
export const renderLayout = atom(false);
export const active_project = atom({ pin: null, pid: null });
export const active_unit = atom({});

export const housing_benefit = atom({});
export const gross_income = atom({ UnformattedValue: null, Value: null });

export const vis_filter = atom(false);
export const vis_sorter = atom(false);
export const vis_detail = atom(false);
export const vis_detail_extended = atom(false);
export const vis_signup = atom(false);
export const vis_mobile_nav = atom(false);
export const vis_desktop_nav = atom(false);
export const vis_overlay = atom(false);
export const rotate_by_slider = atom(true);
export const vis_compare = atom(false);
export const vis_share = atom(false);
export const vis_language_menu = atom(false);
export const vis_calculate_benefit = atom(false);
export const vis_movein_price = atom(false);

export const vis_openhouse = atom(true);
export const scrolly = atom(0);

export const static_assets = atom(null);
export const contact_info = atom([]);
export const nav_info = atom([]);

export const filter_rerender = atom(true);
export const drawer_animation_done = atom(true);
export const share_content = atom({});
export const _fullscreen = atom(false);
export const current_language = atom("");
// console.log("🚀 ~ file: store.global.jsx:44 ~ current_language:", current_language)
export const iso_free = atom(false);
export const iso_isrotating = atom(false);
export const isMulti_project_active = atom(false);
export const fav_grid = atom(false);

// iframe mode
export const iframeMode = atom(false)

export const previousUrl = atom("");
export const a_labHovered = atom("");
export const currentActiveProject = atom("");
export const _drawer = atom((get) => ({
  filter: get(vis_filter),
  sorter: get(vis_sorter),
  detail: get(vis_detail),
}));
