import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function Footer() {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const contactData  = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? []

  return (
    <div>
      <Box
        component="img"
        src={`${STATICS}photo.jpg`}
        sx={{ width: "100%", height: { lg: 504, xs: 260 }, objectFit: "cover" }}
      />

      <Box sx={{ mt: 4, pb: 55, px: { md: 5, xs: 4 }, py: { md: 4, xs: 2 }, lineHeight: 1.4, }} >
        <Box sx={{ fontWeight: 700, fontSize: 20, mb: 4, }} >
          Har du spørgsmål?
        </Box>

        <Grid container sx={{ mb: 5 }} spacing={3}>
          <Grid item lg={6} xs={12}>
            <Box>
              <strong>Lokal mægler</strong> <br />
              danbolig Risskov <br />
              Hørgårdsvej 61 <br />
              8240 Risskov <br />
              <Typography component="a" href="mailto:risskov@danbolig.dk" > risskov@danbolig.dk </Typography> <br />
              86 21 77 00
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ textAlign: { lg: "center", xs: "left" }, }} >
            <Box component="img" src={`${STATICS}footer_1.png`} />
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Box>
              <strong>Projekt mægler</strong> <br />
              danbolig Aarhus - Projektsalg og Udlejning <br />
              Bernhardt Jensens Boulevard 65 <br />
              8000 Aarhus C <br />
              <Typography component="a" href="mailto:aarhus.projektsalg@danbolig.dk"> aarhus.projektsalg@danbolig.dk</Typography> <br />
              86 17 47 00 <br />
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ textAlign: { lg: "center", xs: "left"} }} >
            <Box component="img" src={`${STATICS}footer_1.png`} />
          </Grid>
        </Grid> */}
        <div style={{height:80}}/>
      </Box>
    </div>
  );
}

export default Footer;
