import styled from 'styled-components'
import { KIOSK } from 'constants' 

const href  = 'https://estatetool.dk'
const style = { textDecoration:'none', color:'#000', fontWeight:600 }
const link  = KIOSK ? 'estatetool' : <a href={href} style={style}>estatetool</a> 

export const PoweredBy = ({mode}) => {
 
  return (
    <Powered clr={clr} mode={mode?.toString()}>
      Powered by {link} | &#169; {new Date().getFullYear()}
    </Powered>
  )

}

const Powered = styled.div`
  position: ${({mode}) => mode == 'true' ? 'unset' : 'absolute'};
  bottom: 10px;
  width: 100%;
  color: ${({ clr }) => clr.sub_text_color};
  text-align: center;
  padding: 5px 0;
  white-space: nowrap;
  font-size: 0.75rem;
`