import { Boolean }          from "./Filter.Controls.Boolean";
import { Interval }         from "./Filter.Controls.Interval";
import { MultiSelect }      from "./Filter.Controls.MultiSelect";
import { Dropdown }         from "./Filter.Controls.Dropdown";
import { Col, Row }         from "./Filter.Controls.style";
import { Icon }             from "assets/Icons_";
import { _project }         from "state/store.projects";
import { getProjectData }   from "state/jotai.projectDataHooks";
import { getFocus, useGet } from "state/jotai";
import { active_project }   from "state/store.global";

const dropdownData = {
  Type: "Dropdown",
  Default: [0],
};

export const Controls = ({ active, options, setFilters }) => {
  const { pin, pid }  = useGet(active_project);
  const buttonsLabel  = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const iconLookup    = getFocus(_project, "IconLookup")?.[pin]?.[pid] ?? [];

  const newMultiSelectData = [
    {property:'SalesTypeEjerlejlighed', label: 'Eje'}, 
    {property: 'SalesTypeLejelejlighed', label: 'Leje'}
  ]

  let lookup = {};

  // const lookup = {
  //   'Husleje'       : 'price',
  //   'Månadshyra'    : 'price',
  //   'Størrelse'     : 'area',
  //   'Storlek'       : 'area',
  //   'Etage'         : 'floor',
  //   'Våning'        : 'floor',
  //   'Status'        : 'status',
  //   'Altantype'     : 'balcony',
  //   'Antal værelser': 'rooms',
  //   'Værelser'      : 'rooms',
  //   'Orientering'   : 'orientation',
  //   'Boligtype'     : 'boligtype',
  //   'Rent per month': 'price',
  //   'Size'          : 'area',
  //   'Floor'         : 'floor',
  // }
  const multiSelect = {
    Type: "MultiSelect",
    custom: true,
    Default: [0],
  };
  const PossibleMultiselectValues = newMultiSelectData.map((i, j) => ({
    ID: j,
    Value: i.label,
    ValueProperty: i.property,
  }));
  
  multiSelect.PossibleValues = PossibleMultiselectValues
  multiSelect.Label = "Boligtype"
  multiSelect.customIndex = 0

  // const PossibleValues = getProjectData("unitcategories").map((i, j) => ({
  //   ID: j,
  //   Value: !j
  //     ? buttonsLabel?.filter((btn) => btn?.UILabelProperty === "Boligtype")[0]
  //         ?.Label
  //     : i,
  //   ValueProperty: "Boligtype",
  // }));

  iconLookup?.forEach((icon) => {
    lookup[icon?.Property] = icon?.Label;
  });

  // dropdownData.PossibleValues = PossibleValues;
  // dropdownData.Label = buttonsLabel?.filter(
  //   (btn) => btn?.UILabelProperty === "Boligtype"
  // )[0]?.Label;

  // if (!options.filter((i) => i.Type == "Dropdown").length > 0) {
  //   options.push(dropdownData);
  // }

  if (!options.filter((i) => i?.custom === true).length > 0) {
    options.push(multiSelect);
  }
  
  return (
    <>
      {options.map(({ Type, Label, ...i }, j) => {
        const props   = { ...i, active, setFilters, key: j, index: j, Label };
        const icon = { icon: lookup[Label], color: clr?.icons, size: 40 };

        return (
          <Col key={j}>
              <div
              style={{
                border: "0px solid black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: 65,
              }}
            >
              <Icon clr={clr} {...icon} />
              <div style={{ flex: "0 0 5px" }} />
              <div style={{ position: "relative", top: -7,  color:`${clr.sub_text_color}` }}>{Label}</div>
              </div>
            <Row>
              {Type === "MultiSelect" && <MultiSelect {...props} />}
              {Type === "MultiRange" && <Interval {...props} />}
              {Type === "Boolean"    && <Boolean {...props} />}
              {Type === "Dropdown"   && <Dropdown {...props} />}
            </Row>
          </Col>
        );
      })}
    </>
  );
};
