const palette = {
  primary: {
    main: "#2B2B2B",
    contastText: "#ffffff",
  },
  secondary: {
    main: "#42291F",
    contastText: "#ffffff",
  },
  text: {
    primary: "#000000",
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
