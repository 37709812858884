import { Label, Main } from './Isometry.Controls.Arrows.style'
import { useState }    from 'react'
import { animate }     from 'framer-motion'
import { Arrow }       from './Isometry.Controls.Arrows.assets'
import { useBreak }    from 'hooks/useBreak'
import { motion }      from 'framer-motion'
import { active_project, rotate_by_slider } from 'state/store.global'
import { getFocus, useGet, useSet } from 'state/jotai'
import { _project } from 'state/store.projects'

export const Arrows = ({frame,setFrame,config,index,setIndex}) => {

  const [state, setState] = useState(0)
  const isMobile = useBreak('md_dn')
  const SNP = config.IMAGE_SNAPFRAMES 
  const MAX = SNP?.length - 1
  const MIN = 0
  const setRotate_Done = useSet(rotate_by_slider)
  const { pin, pid } = useGet(active_project)
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  
  const click = ( DIR ) => {
    setRotate_Done(false)

    if ( state == 1 ) return
    setState(1)
    const from = (()=>{
      if ( index == MAX && DIR > 0 ) return SNP?.[MIN]
      if ( index == MIN && DIR < 0 ) return SNP?.[MAX]
      return SNP?.[index]     
    })()
    const to = (()=>{
      if ( index == MAX && DIR > 0 ) return SNP?.[1]
      if ( index == MIN && DIR < 0 ) return SNP?.[MAX-1]
      return SNP?.[index+DIR]
    })()
    const toIndex = (()=>{
      if ( index == MAX && DIR > 0 ) { return 1 }
      if ( index == MIN && DIR < 0 ) { return MAX-1 }
      return index+DIR
    })()
    setIndex( toIndex )
    animate( from, to, {
      duration:0.3,
      transition: { ease:'linear' },
      onUpdate:(v) => {
        if(+v === SNP[toIndex]) setRotate_Done(true)
        setFrame(+v.toFixed(0))
      },
      onComplete:() => setState(0)
    })
  }

  const animation = {
    initial   : { opacity:0 },
    animate   : { opacity:1 },
    exit      : { opacity:0 },
    transition: { duration:0.5, ease:'linear' },
  }

  return (
    <Main as={motion.div} {...animation}>
      <Arrow D={'L'} w={ isMobile ? '30' : '36' } click={() => click(-1)} fill='#2B2B2B'/>
      <Label>{buttonsLabel?.filter( btn => btn.UILabelProperty === 'SkiftVinkel')[0]?.Label}</Label>
      <Arrow D={'R'} w={ isMobile ? '30' : '36' } click={() => click(+1)} fill='#2B2B2B'/>
    </Main>
  )
}
