import styled, {css} from 'styled-components'

export const Thumb = (props, state) => {   
  return (
    <StyledThumb {...props} index={state.index}>
      {state.index === 0 && <Right/> }
      {state.index === 1 && <Left/> }
    </StyledThumb>
  )
}

const StyledThumb = styled.div( () => css``)

const Fig = styled.svg`
  width:15.68px;
  height:15.68px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
`
const Left = () => {
  
  const innerD = 'M7.9 0a7.9 7.9 0 1 1-5.5 2.3A7.9 7.9 0 0 1 7.9 0M9 12.4V4L4.9 8.2z'
  const outerD = 'M7 10.3c-1.2-1.1-2-2-2-2.1 0 0 .8-1 2-2.1l2-2v8.2z'
  const innerC = clr?.sub_text_color
  const outerC = clr?.primary_text
  
  return (
    <Fig viewBox="0 0 15.8 15.8">
      <path d={innerD} fill={innerC} />
      <path d={outerD} fill={outerC} />
    </Fig>
  )
}
const Right = () => {
  
  const innerD = 'M8 0a7.9 7.9 0 1 0 5.5 2.3A7.9 7.9 0 0 0 8 0M6.8 12.4V4L11 8.2z'
  const outerD = 'M6.8 8.2V4L9 6.1l2 2.1-2 2-2 2.2V8.2z'
  const innerC = clr?.sub_text_color
  const outerC = clr?.primary_text
  
  return (
    <Fig viewBox="0 0 15.8 15.8">
      <path d={innerD} fill={innerC} />
      <path d={outerD} fill={outerC} />
    </Fig>
  )
}