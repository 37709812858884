import { useEffect, useState }  from 'react'
import { useFocusGet, useAtom } from 'state/jotai'

import { _bootstrap }           from 'state/store.bootstrap'
import { _project }             from 'state/store.projects'
import { _isometry }            from 'state/store.isometry'
import { _subiso }              from 'state/store.subiso'

import { array } from 'utilities/utility.misc'

export const useProjectSetup = () => {

  const [ res, setRes ] = useState(false)
  
  const [ isometry, setIsometry ] = useAtom(_isometry)
  const [ project , setProject  ] = useAtom(_project)
  const [ subiso  , setSubiso   ] = useAtom(_subiso)

  const _x = {}
  const _y = {}
  const _z = {}
  
  const ARR = useFocusGet(_bootstrap, 'projectids')
  const NUM = ARR?.length || 0
  const BAS = array(NUM, x => ({ [ARR[x]]:[] }) )

  useEffect(()=>{
    if ( BAS.length > 0 ) {
      
      Object.keys(isometry).forEach( x => _x[x] = BAS )
      Object.keys(project ).forEach( y => _y[y] = BAS )
      Object.keys(subiso  ).forEach( z => _z[z] = BAS )
      
      setIsometry(_x)
      setProject (_y)
      setSubiso  (_z)

      setRes(true)

    }
  },[ARR])
  
  return { res }
  
}