import { Box, Divider, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import ContactForm from "./ContactForm";
import { useContactDrawer } from "./useContactDrawer";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { STATICS } from "api/api";

const ContactDrawer = () => {
  const { isOpen, toggle, unit } = useContactDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const contactData  = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? []

  return (
    <Drawer anchor={isMobile ? "bottom" : "right"} open={isOpen} onClose={() => toggle()} PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contastText", backgroundColor: "secondary.main", }, }} >
      <Box sx={{ width: isMobile ? "auto" : 422, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3, }} >
        <IconButton onClick={toggle} sx={{ position: "absolute", right: 16, top: 16, }} >
          <Icon icon="close" sx={{ fontSize: 18, }} />
        </IconButton>
        <Box sx={{ mt: 3, lineHeight: 1.5, }} >
          <Box sx={{ fontWeight: 700, mb: 1 }}>Lokal mægler</Box>
          <Box mb={2}>
            danbolig Risskov <br />
            Hørgårdsvej 61
            8240 Risskov <br />
            arisskov@danbolig.dk <br />
            86 21 77 00
          </Box>
          <Box sx={{ fontWeight: 700, mb: 1 }}>Projekt mægler</Box>
          <Box mb={2}>
            danbolig Aarhus - Projektsalg og Udlejning <br />
            Bernhardt Jensens Boulevard 65
            8000 Aarhus C <br />
            aarhus.projektsalg@danbolig.dk <br />
            86 17 47 00
          </Box>
        </Box>
        <Divider sx={{ my: 4, borderColor: "rgba(0,0,0,0.2)", }}
        />

        {/* <ContactForm unit={unit}>
          <Box
            sx={{
              fontWeight: 700,
              lineHeight: 1.4,
              mb: 3,
            }}
          >
            Udfyld formularen og kom først i køen og bliv inviteret til
            premieren i UNITY Aarhus
          </Box>
        </ContactForm> */}
      </Box>
    </Drawer>
  );
};

export default ContactDrawer;
