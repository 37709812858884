import React from "react";
import { useState } from "react";
import { STATICS } from "api/api";
import { Box } from "@mui/material";

const options = [
  {
    id: "sv",
    label: "Swedish",
    icon: ()=> {
      return <Box
      component="img"
      sx={{
        width: 35,
      }}
      alt="country flag"
      src={`${STATICS}country_flag/sw.svg`} 
    />
    },
  },
  {
    id: "en",
    label: "English",
    icon: ()=> {
      return <Box
      sx={{
        width: 35,
        height: 25

      }}
      component="img"
      alt="country flag"
      src={`${STATICS}country_flag/uk.svg`} 
    />
    },
  },
]

const useLanguage = () => {
  // TODO: dynamic lang.
  const [activeLang, setActiveLang] = useState("da");
  return [activeLang, setActiveLang, options];
}

export default useLanguage;
